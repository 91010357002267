import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../../../src/components/ui/button";
import { ArrowUpDown } from "lucide-react";
import { servicesPriceList } from "../priceList";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.

export const womenColumns: ColumnDef<servicesPriceList>[] =
  [
    {
      accessorKey: "serviceTitle",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="flex justify-start items-center w-fit"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc"
              )
            }
          >
            Service
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        const service = String(
          row.getValue("serviceTitle")
        );
        return (
          <div className="text-left font-medium">
            {service !== "undefined" ? service : "N/A"}
          </div>
        );
      },
    },
    {
      accessorKey: "junior",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="flex justify-center w-fit items-center w-full"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc"
              )
            }
          >
            JUNIOR
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        const junior = String(row.getValue("junior"));
        return (
          <div className="text-center font-medium">
            {junior !== "undefined" ? junior + "$" : "N/A"}
          </div>
        );
      },
    },
    {
      accessorKey: "senior",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="flex justify-center items-center w-full"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc"
              )
            }
          >
            SENIOR
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        const senior = String(row.getValue("senior"));
        return (
          <div className="text-center font-medium">
            {senior !== "undefined" ? senior + "$" : "N/A"}
          </div>
        );
      },
    },
    {
      accessorKey: "prive",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="flex justify-center items-center w-full"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc"
              )
            }
          >
            PRIVE
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        const prive = String(row.getValue("prive"));
        return (
          <div className="text-center font-medium">
            {prive !== "undefined" ? prive + "$" : "N/A"}
          </div>
        );
      },
    },
  ];
