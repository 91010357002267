import React from "react";
import { wooCategory } from "../../lib/interfaces";

import { cn } from "../../lib/utils";
import { WooCommerce } from "../../lib/api";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "../../components/ui/navigation-menu";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { amadeusServices } from "../services/priceList";
export default function CategoriesBar({
  scrolled,
}: {
  scrolled: boolean;
}) {
  const { data: categories } = useQuery(
    ["wooCategories"],
    async () => {
      const res: wooCategory[] = WooCommerce.get(
        "products/categories/?per_page=100"
      )
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });
      return res;
    }
  );
  const nav = useNavigate();
  const { data: tags } = useQuery(["tags"], async () => {
    const res = WooCommerce.get(
      "products/tags/?per_page=100"
    )
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
    return res;
  });
  function removeTagName(tagName: string) {
    if (tagName.includes("Oils")) {
      let words = tagName.split(" ");

      // Remove the last word
      words.pop();
      words.pop();
      words.pop();

      // Join the remaining words back into a sentence
      let newSentence = words.join(" ");
      return newSentence;
    }

    // Split the sentence into an array of words
    let words = tagName.split(" ");

    // Remove the last word
    words.pop();

    // Join the remaining words back into a sentence
    let newSentence = words.join(" ");

    return newSentence;
  }

  const [isHovered, setIsHovered] = React.useState(false);
  const [hoveredIndex, setHoveredIndex] =
    React.useState(null);
  const handleMouseOver = (index: any) => {
    setHoveredIndex(index);
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
    setIsHovered(false);
  };
  const ListItem = React.forwardRef<
    React.ElementRef<"a">,
    React.ComponentPropsWithoutRef<"a">
  >(({ className, title, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
            {...props}
          >
            <div className="text-sm font-medium leading-none">
              {title}
            </div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {children}
            </p>
          </a>
        </NavigationMenuLink>
      </li>
    );
  });
  ListItem.displayName = "ListItem";
  return (
    <NavigationMenu className="opacity-90">
      <NavigationMenuList className="w-screen flex-wrap gap-3 flex justify-center items-center">
        <NavigationMenuItem>
          <NavigationMenuTrigger
            onClick={() =>
              nav("/shop/category/367/Collections")
            }
            className={
              scrolled
                ? "dark:text-neutral-200 text-neutral-900  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-400 p-0"
                : "dark:text-neutral-200 text-neutral-200  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-800 p-0"
            }
          >
            COLLECTIONS
          </NavigationMenuTrigger>
          <NavigationMenuContent
            className={
              "dark:bg-neutral-800 bg-neutral-100 dark:text-neutral-100 z-50 border-0"
            }
          >
            <div className={"w-screen flex p-1 h-full"}>
              <div className="flex justify-center flex-col items-center w-1/3 h-100 p-5 gap-5 ">
                <img
                  src="/stylist.png"
                  alt="tag-illustration"
                  className={"dark:invert w-32"}
                />
                <p className="text-2xl font-medium">
                  COLLECTIONS
                </p>
              </div>
              <div className="flex gap-2 w-full items-start text-xl p-5">
                <div className="w-full">
                  <Link
                    to={`/shop/category/368/kerastase`}
                    className="mb-3 hover:translate-x-2 duration-300  flex gap-1 items-center"
                    key={368}
                  >
                    <span className="font-montserrat  line-clamp-2 leading-snug text-sm">
                      KERASTASE
                    </span>
                  </Link>
                  <div className="flex flex-col flex-wrap">
                    {categories
                      ?.filter(
                        (category: any) =>
                          category.parent === 368
                      )
                      .map(
                        (subCategory: any, index: any) => (
                          <Link
                            to={`/shop/category/${subCategory.id}/${subCategory.name}`}
                            key={subCategory.id}
                            onMouseOver={() =>
                              handleMouseOver(index)
                            }
                            onMouseOut={handleMouseOut}
                            className="w-1/2 hover:translate-x-2 duration-300  flex gap-1 items-center text-[12px] font-light"
                          >
                            <p
                              className={
                                hoveredIndex === index &&
                                isHovered
                                  ? `w-[10px] h-[2px] duration-500 bg-neutral-800 dark:bg-neutral-100`
                                  : "h-[1px] w-0"
                              }
                            ></p>
                            {subCategory.name}
                          </Link>
                        )
                      )}
                  </div>
                </div>

                <div className="w-full">
                  <Link
                    to={`/shop/category/437/Schwarzkopf`}
                    className="mb-3 hover:translate-x-2 duration-300 flex gap-1 items-center"
                    key={437}
                  >
                    <span className="font-montserrat  line-clamp-2 leading-snug text-sm">
                      SCHWARZKOPF
                    </span>
                  </Link>
                  <div className="flex flex-wrap ">
                    {categories
                      ?.filter(
                        (category: any) =>
                          category.parent === 437
                      )
                      .map((subCategory: any) => (
                        <Link
                          to={`/shop/category/${subCategory.id}/${subCategory.name}`}
                          key={subCategory.id}
                          onMouseOver={() =>
                            handleMouseOver(subCategory.id)
                          }
                          onMouseOut={handleMouseOut}
                          className="w-1/2 flex gap-1 items-center text-[12px] font-light"
                        >
                          <p
                            className={
                              hoveredIndex ===
                                subCategory.id && isHovered
                                ? `w-[10px] h-[2px] duration-500 bg-neutral-800 dark:bg-neutral-100`
                                : "h-[1px] w-0"
                            }
                          ></p>
                          {subCategory.name}
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            onClick={() =>
              nav("/shop/category/375/SHAMPOOS")
            }
            className={
              scrolled
                ? "dark:text-neutral-200 text-neutral-900  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-400 p-0"
                : "dark:text-neutral-200 text-neutral-200  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-800 p-0"
            }
          >
            SHAMPOOS
          </NavigationMenuTrigger>
          <NavigationMenuContent
            className={
              "dark:bg-neutral-800 bg-neutral-100 dark:text-neutral-100 z-50 border-0"
            }
          >
            <div className="flex w-screen justify-start items-start">
              <div className="flex justify-center flex-col items-center w-1/3 h-full p-5 gap-5">
                <img
                  src="/stylist.png"
                  alt="tag-illustration"
                  className={"dark:invert w-32"}
                />
                <p className="text-2xl font-medium">
                  SHAMPOOS
                </p>
              </div>
              <div className="w-screen flex gap-x-10 gap-2 p-5 flex-wrap w-full justify-start items-start">
                {tags
                  ?.filter((tag: any) =>
                    tag.slug.includes(`shampo`)
                  )
                  .map((tag: any, index: any) => (
                    <Link
                      onMouseOver={() =>
                        handleMouseOver(index)
                      }
                      onMouseOut={handleMouseOut}
                      to={`/shop/hairType/${tag.id}/${tag.name}`}
                      key={tag.id}
                      className="w-1/4 flex gap-1 items-center text-[12px] font-light"
                    >
                      <p
                        className={
                          hoveredIndex === index &&
                          isHovered
                            ? `w-[10px] h-[2px] duration-500 bg-neutral-800 dark:bg-neutral-100`
                            : "h-[1px] w-0"
                        }
                      ></p>
                      {tag.name}
                    </Link>
                  ))}
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            onClick={() =>
              nav("/shop/category/373/CONDITIONERS")
            }
            className={
              scrolled
                ? "dark:text-neutral-200 text-neutral-900  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-400 p-0"
                : "dark:text-neutral-200 text-neutral-200  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-800 p-0"
            }
          >
            CONDITIONERS
          </NavigationMenuTrigger>
          <NavigationMenuContent
            className={
              "dark:bg-neutral-800 bg-neutral-100 dark:text-neutral-100 z-50 border-0"
            }
          >
            <div className="flex w-screen justify-center items-start">
              <div className="flex justify-center flex-col items-center w-1/3 h-full p-5 gap-5">
                <img
                  src="/stylist.png"
                  alt="tag-illustration"
                  className={"dark:invert w-32"}
                />
                <p className="text-2xl font-medium">
                  CONDITIONERS
                </p>
              </div>
              <div className="w-screen flex gap-x-10 gap-2 p-5 flex-wrap w-full justify-start items-start">
                {tags
                  ?.filter((tag: any) =>
                    tag.name.includes(`Condition`)
                  )
                  .map((tag: any) => (
                    <Link
                      to={`/shop/hairType/${tag.id}/${tag.name}`}
                      key={tag.id}
                      onMouseOver={() =>
                        handleMouseOver(tag.id)
                      }
                      onMouseOut={handleMouseOut}
                      className="w-1/4 flex gap-1 items-center text-[12px] font-light"
                    >
                      <p
                        className={
                          hoveredIndex === tag.id &&
                          isHovered
                            ? `w-[10px] h-[2px] duration-500 bg-neutral-800 dark:bg-neutral-100`
                            : "h-[1px] w-0"
                        }
                      ></p>
                      {tag.name}
                    </Link>
                  ))}
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            onClick={() =>
              nav("/shop/category/378/STYLING")
            }
            className={
              scrolled
                ? "dark:text-neutral-200 text-neutral-900  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-400 p-0"
                : "dark:text-neutral-200 text-neutral-200  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-800 p-0"
            }
          >
            <Link to={`shop/category/378/Styling`}>
              STYLING
            </Link>
          </NavigationMenuTrigger>

          <NavigationMenuContent
            className={
              "dark:bg-neutral-800 bg-neutral-100 dark:text-neutral-100 z-50 border-0"
            }
          >
            <div className="flex w-screen justify-center items-start">
              <div className="flex justify-center flex-col items-center w-1/4 h-full p-5 gap-5">
                <img
                  src="/stylist.png"
                  alt="tag-illustration"
                  className={"dark:invert w-32"}
                />
                <p className="text-2xl font-medium">
                  STYLING
                </p>
              </div>
              <div className="w-screen gap-x-1 gap-y-2 p-5 flex items-start">
                {categories
                  ?.filter(
                    (subCategory: wooCategory) =>
                      subCategory.parent === 378
                  )
                  .map((sub) => (
                    <div key={sub.id} className="w-full">
                      <Link
                        to={`/shop/category/${sub.id}/${sub.name}`}
                        className="mb-3 flex gap-1 hover:translate-x-2 duration-300 items-center font-montserrat text-sm"
                      >
                        {sub.name.toLocaleUpperCase()}
                      </Link>
                      <div className="flex flex-col gap-2 w-full">
                        {tags
                          ?.filter((tag: any) =>
                            tag.name.includes(`${sub.name}`)
                          )
                          .map((tag: any) => (
                            <Link
                              to={`/shop/hairType/${tag.id}/${tag.name}`}
                              key={tag.id}
                              onMouseOver={() =>
                                handleMouseOver(tag.id)
                              }
                              onMouseOut={handleMouseOut}
                              className=" flex text-[12px] font-light items-center gap-1"
                            >
                              <p
                                className={
                                  hoveredIndex === tag.id &&
                                  isHovered
                                    ? `w-[10px] h-[2px] duration-500 bg-neutral-800 dark:bg-neutral-100`
                                    : "h-[1px] w-0"
                                }
                              ></p>
                              {removeTagName(tag.name)}
                            </Link>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            onClick={() =>
              nav("/shop/category/366/OILS AND SERUMS")
            }
            className={
              scrolled
                ? "dark:text-neutral-200 text-neutral-900  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-400 p-0"
                : "dark:text-neutral-200 text-neutral-200  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-800 p-0"
            }
          >
            OILS AND SERUMS
          </NavigationMenuTrigger>
          <NavigationMenuContent
            className={
              "dark:bg-neutral-800 bg-neutral-100 dark:text-neutral-100 z-50 border-0"
            }
          >
            <div className="flex w-screen justify-center items-start">
              <div className="flex justify-center flex-col items-center w-1/3 h-full p-5 gap-5">
                <img
                  src="/stylist.png"
                  alt="tag-illustration"
                  className={"dark:invert w-32"}
                />
                <p className="text-2xl font-medium">
                  OILS AND SERUMS
                </p>
              </div>
              <div className="w-screen flex gap-x-10 gap-2 p-5 flex-wrap w-full justify-start items-center">
                {tags
                  ?.filter((tag: any) =>
                    tag.slug.includes(`serum`)
                  )
                  .map((tag: any) => (
                    <Link
                      to={`/shop/hairType/${tag.id}/${tag.name}`}
                      key={tag.id}
                      onMouseOver={() =>
                        handleMouseOver(tag.id)
                      }
                      onMouseOut={handleMouseOut}
                      className="w-1/3 flex gap-1 items-center text-[12px] font-light"
                    >
                      <p
                        className={
                          hoveredIndex === tag.id &&
                          isHovered
                            ? `w-[10px] h-[2px] duration-500 bg-neutral-800 dark:bg-neutral-100`
                            : "h-[1px] w-0"
                        }
                      ></p>
                      {tag.name}
                    </Link>
                  ))}
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            onClick={() =>
              nav("/shop/category/410/TREATMENTS")
            }
            className={
              scrolled
                ? "dark:text-neutral-200 text-neutral-900  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-400 p-0"
                : "dark:text-neutral-200 text-neutral-200  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-800 p-0"
            }
          >
            TREATMENTS
          </NavigationMenuTrigger>
          <NavigationMenuContent
            className={
              "dark:bg-neutral-800 bg-neutral-100 dark:text-neutral-100 z-50 border-0"
            }
          >
            <div className="flex w-screen justify-center items-start">
              <div className="flex justify-center flex-col items-center w-1/3 h-full p-5 gap-5">
                <img
                  src="/stylist.png"
                  alt="tag-illustration"
                  className={"dark:invert w-32"}
                />
                <p className="text-2xl font-medium">
                  TREATMENTS
                </p>
              </div>
              <div className="w-screen flex gap-x-10 gap-2 p-5 flex-wrap w-full justify-start items-start">
                {tags
                  ?.filter((tag: any) =>
                    tag.slug.includes(`treatment`)
                  )
                  .map((tag: any) => (
                    <Link
                      to={`/shop/hairType/${tag.id}/${tag.name}`}
                      key={tag.id}
                      onMouseOver={() =>
                        handleMouseOver(tag.id)
                      }
                      onMouseOut={handleMouseOut}
                      className="w-1/4  flex gap-1 items-center text-[12px] font-light"
                    >
                      <p
                        className={
                          hoveredIndex === tag.id &&
                          isHovered
                            ? `w-[10px] h-[2px] duration-500 bg-neutral-800 dark:bg-neutral-100`
                            : "h-[1px] w-0"
                        }
                      ></p>

                      {tag.name}
                    </Link>
                  ))}
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            onClick={() => nav("/services")}
            className={
              scrolled
                ? "dark:text-neutral-200 text-neutral-900  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-400 p-0"
                : "dark:text-neutral-200 text-neutral-200  data-[state=open]:bg-transparent data-[state=closed]:bg-transparent hover:text-neutral-800 p-0"
            }
          >
            SERVICES
          </NavigationMenuTrigger>
          <NavigationMenuContent
            className={
              "dark:bg-neutral-800 bg-neutral-100 dark:text-neutral-100 z-50 border-0"
            }
          >
            <div className="flex w-screen justify-center items-start">
              <div className="flex justify-center flex-col items-center w-1/3 h-full p-5 gap-5">
                <img
                  src="/stylist.png"
                  alt="tag-illustration"
                  className={"dark:invert w-32"}
                />
                <p className="text-2xl font-medium">
                  SERVICES
                </p>
              </div>
              <div className="w-screen flex gap-x-10 gap-2 p-5 flex-wrap w-full justify-start items-start">
                {amadeusServices.map((service: any) => (
                  <Link
                    to={service.link}
                    key={service.id}
                    onMouseOver={() =>
                      handleMouseOver(service.id)
                    }
                    onMouseOut={handleMouseOut}
                    className="w-1/4  flex gap-1 items-center text-[12px] font-light"
                  >
                    <p
                      className={
                        hoveredIndex === service.id &&
                        isHovered
                          ? `w-[10px] h-[2px] duration-500 bg-neutral-800 dark:bg-neutral-100`
                          : "h-[1px] w-0"
                      }
                    ></p>

                    {service.service}
                  </Link>
                ))}
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        {/* <Link
          to="/services"
          className={`text-[14px] hover:text-neutral-500 font-medium ${
            scrolled
              ? "text-neutral-900 dark:text-neutral-100"
              : "text-neutral-100"
          }`}
        >
          SERVICES
        </Link> */}
      </NavigationMenuList>
    </NavigationMenu>
  );
}
