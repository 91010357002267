import React from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Label } from "../../components/ui/label";
import { useCookies } from "react-cookie";
import {
  ExclamationTriangleIcon,
  LockClosedIcon,
} from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { CheckIcon } from "lucide-react";
import { useQueryClient } from "react-query";
import { toast } from "../../components/ui/use-toast";
import { apiCall } from "../../../src/lib/api";
import EyePassword from "../../../src/components/eyePassword";

export default function ChangePassword({
  id,
}: {
  id: string;
}) {
  const [cookies] = useCookies(["userToken"]);
  const [passOne, setPassOne] = React.useState<string>("");
  const [passTwo, setPassTwo] = React.useState<string>("");
  const reactQuery = useQueryClient();
  const updatePassword = async (
    id: string,
    password: string,
    token: string
  ) => {
    try {
      const response = await fetch(
        `${apiCall}/wp-json/wp/v2/users/${id}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            password: password,
          }),
        }
      );
      const data = await response.json();

      toast({
        title: "Password Change",
        description: "Password changed successfully!",
        action: <CheckIcon />,
        className: "fixed top-0 left-0 sm:relative",
      });
      reactQuery.refetchQueries(["users/me"]);
      return data;
    } catch (error) {
      console.error("Error", error);
    }
  };
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="flex justify-start w-full border gap-2 rounded-none"
        >
          <LockClosedIcon className="w-4 h-4" />
          <p>CHANGE PASSWORD</p>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Change Password</DialogTitle>
        </DialogHeader>
        <DialogDescription className="hidden"></DialogDescription>
        <form className="grid gap-4 py-4">
          <div className="flex gap-2 flex-col justify-between">
            <Label htmlFor="firstName">
              Enter Password
            </Label>
            <div className="flex gap-2 items-center justify-between">
              <EyePassword
                value={passOne}
                setValue={setPassOne}
              />
              {passOne.length < 8 ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <ExclamationTriangleIcon
                        className="h-5 w-5"
                        color="red"
                      />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        Password should be 8 characters or
                        more
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <CheckIcon
                  color="green"
                  className="h-5 w-5"
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2 justify-between">
            <Label htmlFor="lastName">
              Repeat Password
            </Label>
            <div className="flex gap-2 items-center justify-between">
              <EyePassword
                value={passTwo}
                setValue={setPassTwo}
              />
              {passOne !== passTwo ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <ExclamationTriangleIcon
                        className="h-5 w-5"
                        color="red"
                      />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Passwords does not match</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <CheckIcon
                  color="green"
                  className="h-5 w-5"
                />
              )}
            </div>
          </div>
        </form>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              type="submit"
              className="rounded-none"
              onClick={() =>
                updatePassword(
                  id,
                  passOne,
                  cookies.userToken
                )
              }
            >
              CHANGE PASSWORD
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
