import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/ui/carousel";

interface galleryImgs {
  id: number;
  src: string;
}
export function Gallery({
  imgs,
  img,
  w,
}: {
  imgs: galleryImgs[];
  img: string;
  w: string;
}) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="relative cursor-pointer">
          <div className="absolute bottom-0 h-1/3 w-full bg-gradient-to-t from-[#ffffff99] from-[#00000030]  flex items-end p-2">
            <span className="text-black hover:underline">
              {" "}
              VIEW GALLERY
            </span>
          </div>
          <img src={img} alt="sss" className={`${w}`} />
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-xl gap-5 bg-[#17171770] rounded-none border-none text-neutral-100  flex flex-col justify-center items-center">
        <DialogHeader className="w-full">
          <DialogTitle className="text-2xl font-trajan">
            Gallery
          </DialogTitle>
        </DialogHeader>
        <Carousel opts={{ loop: true }}>
          <CarouselContent className="w-100">
            {imgs.map((img: galleryImgs) => (
              <CarouselItem key={img.id}>
                <img src={img.src} alt="sss" />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious className="border-0 bg-transparent left-0" />
          <CarouselNext className="border-0 bg-transparent right-0" />
        </Carousel>
      </DialogContent>
    </Dialog>
  );
}
