import React from "react";
import Navbar from "../navBar/navbar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../footer/Footer";
import ScrollToTop from "../../../src/components/scrollToTop";
import MobileBar from "../navBar/mobileBar";

export default function UserLayout() {
  const { pathname } = useLocation();

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  React.useEffect(() => {
    const mediaQuery = window.matchMedia(
      "(max-width: 768px)"
    );
    const handleChange = () =>
      setIsMobile(mediaQuery.matches);

    mediaQuery.addEventListener("change", handleChange);
    return () =>
      mediaQuery.removeEventListener(
        "change",
        handleChange
      );
  }, []);

  return (
    <div className="flex flex-col justify-between min-h-screen min-w-[300px]">
      <ScrollToTop />
      {isMobile && <MobileBar isMobile />}
      <Navbar location={pathname} />
      <Outlet />
      <Footer />
    </div>
  );
}
