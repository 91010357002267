import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../../../src/components/ui/button";
import { ArrowUpDown } from "lucide-react";
import { servicesPriceList } from "../priceList";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.

export const menColumns: ColumnDef<servicesPriceList>[] = [
  {
    accessorKey: "serviceTitle",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="flex justify-start items-center w-fit"
          onClick={() =>
            column.toggleSorting(
              column.getIsSorted() === "asc"
            )
          }
        >
          Service
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const service = String(row.getValue("serviceTitle"));
      return (
        <div className="text-left font-medium">
          {service !== "undefined" ? service : "N/A"}
        </div>
      );
    },
  },
  {
    accessorKey: "junior",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="flex justify-center w-fit items-center w-full"
          onClick={() =>
            column.toggleSorting(
              column.getIsSorted() === "asc"
            )
          }
        >
          Junior
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const price = String(row.getValue("junior"));
      return (
        <div className="text-center font-medium">
          {price !== "undefined" ? price + "$" : "N/A"}
        </div>
      );
    },
  },
];
