import * as React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../components/ui/carousel";
import { productImage } from "../pages/products/productInterface";

export function ProductCarousel({
  images,
  setImageOnClick,
}: {
  images: productImage[];
  setImageOnClick: (src: string) => void;
}) {
  function setMainImgSrc(src: string): void {
    setImageOnClick(src);
  }

  return (
    <Carousel
      className="p-10 w-full relative"
      opts={{ loop: true }}
    >
      <CarouselContent className="p-0 m-0">
        {images?.map((img) => (
          <CarouselItem
            key={img?.id}
            className="basis-1/3"
            onClick={() => setMainImgSrc(img.src)}
          >
            <img
              src={img?.src}
              alt="sss"
              className=" w-24"
            />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className="z-30 left-0 absolute" />
      <CarouselNext className="absolute z-30 right-0" />
    </Carousel>
  );
}
