import React from "react";
import { Gallery } from "./gallery";
import { Badge } from "../../../src/components/ui/badge";

const imgs = [
  {
    id: 1,
    src: "/salon-1.jpg",
  },
  {
    id: 2,
    src: "/salon-2.jpg",
  },
  {
    id: 3,
    src: "/salon-3.jpg",
  },
  {
    id: 4,
    src: "/salon-4.jpg",
  },
];
export default function Venue({
  floor,
  img,
}: {
  floor: number;
  img: number;
}) {
  return (
    <div
      className={`w-full z-50 flex justify-start items-start flex-col-reverse gap-10`}
    >
      <div className="w-full flex flex-col h-full justify-center gap-5 bg-transparent">
        <div className="flex flex-col gap-2">
          <h2 className="lg:text-4xl text-xl font-trajan text-neutral-100">
            {floor === 1 ? "First" : "Second"} Floor
          </h2>
          <div className="text-neutral-100 flex flex-col gap-2 sm:text-sm text-[12px]">
            {floor === 1 && (
              <p>
                The industrial style, weathered wood,
                building systems, industrial lighting and
                concrete elements are dominant across the
                space.
              </p>
            )}
            {floor === 1 && (
              <p className="hidden lg:block">
                Every area leads smoothly to the next one in
                a subtle flow, while maintaining an overall
                sense of luxurious intimacy. Round mirrors
                are carefully inserted into the precise
                rectilinear architecture.
              </p>
            )}

            {floor === 2 && (
              <p>
                Understated colors, predominantly white,
                black, and grey, are contrasted with a touch
                of mustard to create an atmosphere of peace
                and luxury.
              </p>
            )}
            {floor === 2 && (
              <p className="hidden lg:block">
                The space bathes in natural daylight
                complemented with greenery, adding clarity
                and a natural appeal to the architecture.
              </p>
            )}
          </div>
          {floor === 1 && (
            <div
              className={`flex gap-2 w-full lg:flex-nowrap flex-wrap `}
            >
              <Badge
                className="border-neutral-500 w-36 flex justify-center text-neutral-100 rounded-none"
                variant={"outline"}
              >
                The Hair SPA
              </Badge>
              <Badge
                className="border-neutral-500 w-36 flex justify-center text-neutral-100 rounded-none"
                variant={"outline"}
              >
                The Private Area
              </Badge>
              <Badge
                className="border-neutral-500 w-36 flex justify-center text-neutral-100 rounded-none"
                variant={"outline"}
              >
                The Makeup Lab
              </Badge>
              <Badge
                className="border-neutral-500 w-36 flex justify-center text-neutral-100 rounded-none"
                variant={"outline"}
              >
                Photography Studio
              </Badge>
            </div>
          )}

          {floor === 2 && (
            <div
              className={`flex gap-2 w-full lg:flex-nowrap flex-wrap justify-start `}
            >
              <Badge
                className="border-neutral-500 w-36 flex justify-center text-neutral-100 rounded-none"
                variant={"outline"}
              >
                Main Reception Hall
              </Badge>
              <Badge
                className="border-neutral-500 w-36 flex justify-center text-neutral-100 rounded-none"
                variant={"outline"}
              >
                The Hair Lab For Her
              </Badge>
              <Badge
                className="border-neutral-500 w-36 flex justify-center text-neutral-100 rounded-none"
                variant={"outline"}
              >
                The Hair Lab For Him
              </Badge>
              <Badge
                className="border-neutral-500 w-36 flex justify-center text-neutral-100 rounded-none"
                variant={"outline"}
              >
                Retail Area
              </Badge>
            </div>
          )}
        </div>
      </div>
      <Gallery
        imgs={imgs}
        img={`/salon-${img}.jpg`}
        w={"lg:w-64 w-36"}
      />
    </div>
  );
}
