import React from "react";
import { Button } from "../../../../src/components/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { menColumns } from "../men/menColumns";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { MenServices } from "./men";
import {
  amadeusMenHairServices,
  amadeusSpaMenServices,
} from "../priceList";
export function LebanonMen() {
  const tab = useParams().tab;
  const nav = useNavigate();

  return (
    <div className="sm:p-32 p-5 mt-10">
      <h2 className="text-xl sm:text-2xl font-montserrat font-medium mb-5">
        AMADEUS MEN
      </h2>
      <Tabs
        defaultValue={tab || "hair"}
        className="sm:w-4/6 w-full"
        value={tab}
      >
        <TabsList className="flex w-full gap-1  h-full rounded-none justify-between items-center">
          <TabsTrigger
            value="hair"
            className="hover:underline w-full rounded-none"
            onClick={() =>
              (window.location.href =
                "/services/lebanon/lebanonMen/hair")
            }
          >
            HAIR
          </TabsTrigger>
          <TabsTrigger
            value="spa"
            className="hover:underline  w-full rounded-none"
            onClick={() =>
              (window.location.href =
                "/services/lebanon/lebanonMen/spa")
            }
          >
            SPA
          </TabsTrigger>
        </TabsList>
        <TabsContent value="hair" className="w-full">
          <MenServices
            columns={menColumns}
            data={amadeusMenHairServices}
          />
        </TabsContent>

        <TabsContent value="spa">
          <MenServices
            columns={menColumns}
            data={amadeusSpaMenServices}
          />
        </TabsContent>
      </Tabs>
      <div className="flex flex-col gap-5 justify-end right-32 p-5 items-center lg:fixed top-48">
        <video
          width="200px"
          autoPlay
          muted
          playsInline
          loop
          controls={false}
        >
          <source
            src={"/amadeus-videos/amadeus-ad-1.mp4"}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <p className="text-sm text-center">
          match our products with your required service
        </p>
        <Button
          className="rounded-none w-36"
          onClick={() => {
            nav("/shop");
          }}
        >
          SHOP NOW
        </Button>
      </div>
    </div>
  );
}
