import { Button } from "../../../src/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "../../../src/components/ui/card";
import { useNavigate } from "react-router-dom";
import { cartItem } from "../products/productInterface";
import Coupons from "./coupons";
import { LoaderIcon } from "lucide-react";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { apiCall } from "../../../src/lib/api";

export default function CartDetails({
  loading,
  checkout,
}: {
  loading?: boolean;
  checkout?: boolean;
}) {
  const nav = useNavigate();
  const [cookies] = useCookies(["userToken", "cartKey"]);
  const { data: cart } = useQuery(
    ["cartItems"],
    async () => {
      const key = await cookies.cartKey;
      const res = await fetch(
        !cookies.userToken
          ? `${apiCall}/wp-json/cocart/v2/cart/?cart_key=${key}`
          : `${apiCall}/wp-json/cocart/v2/cart/`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
            Authorization: !cookies.userToken
              ? ""
              : `Bearer ${cookies.userToken}`,
          },
        }
      );
      if (!res.ok) {
        console.log(`HTTP error: ${res.status}`);
      }
      const response = await res.json();
      return response;
    },
    { enabled: !!cookies.cartKey || !!cookies.userToken }
  );
  return (
    <div className="flex flex-col w-full">
      <Card className="w-full rounded-none">
        <CardHeader>
          <CardTitle className="border-b pb-2 text-xl font-montserrat font-medium">
            CART DETAILS
          </CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-2 font-montserrat">
          <div className="flex flex-col gap-3">
            {cart?.items?.map((item: cartItem) => (
              <div
                key={item.item_key}
                className="flex gap-5 border-b text-sm justify-between items-center w-full"
              >
                <p className="w-12">
                  {item.quantity.value} x
                </p>
                <p className="grow text-[12px] w-full">
                  {item.name.toLocaleUpperCase()}
                </p>
                <p className="">
                  {item.totals.subtotal || "0"}$
                </p>
              </div>
            ))}
          </div>
          <div className="border-b pt-5 pb-2 flex justify-between items-center">
            <p className="font-medium">SUBTOTAL</p>
            <p className="font-bold">
              {loading ? (
                <LoaderIcon className="animate-spin w-4 h-4" />
              ) : (
                `${cart?.totals?.subtotal || "0"}$`
              )}
            </p>
          </div>
          <Coupons />
          <div className=" pt-5 pb-2 flex justify-between items-center">
            <p className="font-medium">TOTAL</p>
            <p className="font-bold">
              {loading ? (
                <LoaderIcon className="animate-spin w-4 h-4" />
              ) : (
                `${cart?.totals?.total || "0"}$`
              )}
            </p>
          </div>
        </CardContent>
        {!checkout && (
          <CardFooter>
            <Button
              onClick={() => nav("/checkout")}
              className="w-full rounded-none"
              disabled={!cart?.items}
            >
              PROCEED TO CHECKOUT
            </Button>
          </CardFooter>
        )}
      </Card>
    </div>
  );
}
