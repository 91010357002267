import React from "react";
import { wooCategory } from "../lib/interfaces";
import { WooCommerce } from "../lib/api";
import SocialIcons from "./socialIcons";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet";
import { MenuIcon } from "lucide-react";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { ModeToggle } from "./themeToggle";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { amadeusServices } from "../pages/services/priceList";
export default function CategoriesDrawer({
  scrolled,
}: {
  scrolled: boolean;
}) {
  const { data: categories } = useQuery(
    ["wooCategories"],
    async () => {
      const res: wooCategory[] = WooCommerce.get(
        "products/categories/?per_page=100"
      )
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });
      return res;
    }
  );
  const { data: tags } = useQuery(["tags"], async () => {
    const res = WooCommerce.get(
      "products/tags/?per_page=100"
    )
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
    return res;
  });
  function removeTagName(tagName: string) {
    if (tagName.includes("Oils")) {
      let words = tagName.split(" ");

      // Remove the last word
      words.pop();
      words.pop();
      words.pop();

      // Join the remaining words back into a sentence
      let newSentence = words.join(" ");
      return newSentence;
    }

    // Split the sentence into an array of words
    let words = tagName.split(" ");

    // Remove the last word
    words.pop();

    // Join the remaining words back into a sentence
    let newSentence = words.join(" ");

    return newSentence;
  }

  return (
    <Sheet aria-describedby="left drawer">
      <SheetTrigger name="burger menu">
        <MenuIcon
          className={
            scrolled
              ? "h-5 w-5 mt-[1px] "
              : "text-neutral-200 h-5 w-5 mt-[1px] "
          }
        />
      </SheetTrigger>

      <SheetContent
        side={"left"}
        className="outline-none flex flex-col p-3"
        aria-describedby="hairCare"
      >
        <SheetHeader>
          <SheetTitle className="flex justify-center mt-2">
            <img
              src="/logo-aaa.png"
              alt=""
              className={"dark:invert w-12"}
            />
          </SheetTitle>
        </SheetHeader>
        <SheetDescription className="hidden">
          Filter by category
        </SheetDescription>
        <Tabs
          defaultValue="hair-care"
          className="font-montserrat outline-none focus:outline-none flex flex-col"
          aria-describedby="hairCare"
        >
          <TabsList className="w-full rounded-none outline-none focus:outline-none">
            <TabsTrigger
              value="hair-care"
              className="w-full pt-2 rounded-none text-sm"
            >
              HAIR CARE
            </TabsTrigger>
            <TabsTrigger
              value="collections"
              className="w-full pt-2 rounded-none text-sm"
            >
              COLLECTIONS
            </TabsTrigger>
          </TabsList>

          <TabsContent
            value="hair-care"
            className="outline-none focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-none focus-visible:ring-offset-0 "
          >
            <Accordion
              type="single"
              collapsible
              className="w-full text-sm"
            >
              <AccordionItem
                value={"SHAMPOOS"}
                className="dark:border-b dark:border-b-neutral-800 mt-5 pb-1 data-[state=open]:border-b-0"
              >
                <AccordionTrigger className="hover:no-underline hover:text-neutral-500 p-0 pb-2 font-normal">
                  <SheetClose
                    asChild
                    className={`flex justify-between hover:underline cursor-pointer items-center font-montserrat w-fit`}
                  >
                    <Link
                      to={`/shop/category/375/SHAMPOOS`}
                    >
                      SHAMPOOS
                    </Link>
                  </SheetClose>
                </AccordionTrigger>

                <AccordionContent className="p-2 flex flex-col gap-2 sm:max-h-[400px] max-h-[150px] min-h-[150px] overflow-scroll no-scrollbar">
                  {tags
                    ?.filter((tag: any) =>
                      tag.slug.includes(`shampo`)
                    )
                    .map((tag: any) => (
                      <SheetClose asChild key={tag.id}>
                        <Link
                          to={`/shop/hairType/${tag.id}/${tag.name}`}
                          className="text-[12px] font-light border-b pb-2 cursor-pointer border-b-neutral-200 dark:border-b-neutral-800 hover:text-neutral-600"
                        >
                          {tag.name}
                        </Link>
                      </SheetClose>
                    ))}
                </AccordionContent>
              </AccordionItem>
              <AccordionItem
                value={"CONDITIONERS"}
                className="dark:border-b dark:border-b-neutral-800 mt-5 pb-1 data-[state=open]:border-b-0"
              >
                <AccordionTrigger className="hover:no-underline hover:text-neutral-500 p-0 pb-2 font-normal">
                  <SheetClose asChild>
                    <Link
                      to={`/shop/category/373/CONDITIONERS`}
                    >
                      CONDITIONERS
                    </Link>
                  </SheetClose>
                </AccordionTrigger>

                <AccordionContent className="p-2 flex flex-col gap-2 sm:max-h-[400px] max-h-[150px] min-h-[150px] overflow-scroll no-scrollbar">
                  {tags
                    ?.filter((tag: any) =>
                      tag.name.includes(`Condition`)
                    )
                    .map((tag: any) => (
                      <SheetClose asChild key={tag.id}>
                        <Link
                          to={`/shop/hairType/${tag.id}/${tag.name}`}
                          className="text-[12px] font-light border-b pb-2 cursor-pointer border-b-neutral-200 dark:border-b-neutral-800 hover:text-neutral-600 "
                        >
                          {tag.name}
                        </Link>
                      </SheetClose>
                    ))}
                </AccordionContent>
              </AccordionItem>
              <AccordionItem
                value={"STYLING"}
                className="dark:border-b dark:border-b-neutral-800 mt-5 pb-1 data-[state=open]:border-b-0 w-full"
              >
                <AccordionTrigger className="hover:no-underline hover:text-neutral-500 p-0 pb-2 font-normal">
                  <SheetClose
                    asChild
                    className={`flex justify-between hover:underline cursor-pointer items-center font-montserrat w-fit`}
                  >
                    <Link to={`shop/category/378/Styling`}>
                      STYLING
                    </Link>
                  </SheetClose>
                </AccordionTrigger>

                <AccordionContent className="w-full flex flex-col gap-2 sm:max-h-[400px] max-h-[150px] min-h-[150px] overflow-scroll no-scrollbar">
                  <Accordion
                    type="single"
                    collapsible
                    className="pl-4 pr-4"
                  >
                    {categories
                      ?.filter(
                        (subCategory: wooCategory) =>
                          subCategory.parent === 378
                      )
                      .map((sub) => (
                        <AccordionItem
                          key={sub.id}
                          value={sub.name}
                          className="dark:border-b dark:border-b-neutral-800 data-[state=open]:border-b-0"
                        >
                          <AccordionTrigger className="hover:no-underline hover:text-neutral-500 font-normal">
                            <SheetClose asChild>
                              <Link
                                to={`/shop/category/${sub.id}/${sub.name}`}
                                className="font-medium"
                              >
                                {sub.name.toLocaleUpperCase()}
                              </Link>
                            </SheetClose>
                          </AccordionTrigger>
                          <AccordionContent className="flex flex-col gap-1 p-2">
                            {tags
                              ?.filter((tag: any) =>
                                tag.name.includes(
                                  `${sub.name}`
                                )
                              )
                              .map((tag: any) => (
                                <SheetClose
                                  asChild
                                  key={tag.id}
                                >
                                  <Link
                                    to={`/shop/hairType/${tag.id}/${tag.name}`}
                                    className="text-[12px] font-light border-b pb-2 cursor-pointer border-b-neutral-200 dark:border-b-neutral-800 hover:text-neutral-600 "
                                  >
                                    {removeTagName(
                                      tag.name
                                    )}
                                  </Link>
                                </SheetClose>
                              ))}
                          </AccordionContent>
                        </AccordionItem>
                      ))}
                  </Accordion>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem
                value={"OILS AND SERUMS"}
                className="dark:border-b dark:border-b-neutral-800 mt-5 pb-1 data-[state=open]:border-b-0"
              >
                <AccordionTrigger className="hover:no-underline hover:text-neutral-500 p-0 pb-2 font-normal">
                  <SheetClose
                    asChild
                    className={`flex justify-between hover:underline cursor-pointer items-center font-montserrat w-fit`}
                  >
                    <Link to={`shop/category/378/Styling`}>
                      OILS AND SERUMS
                    </Link>
                  </SheetClose>
                </AccordionTrigger>
                <AccordionContent className="flex flex-col gap-2 p-2 sm:max-h-[400px] max-h-[150px] min-h-[150px] overflow-scroll no-scrollbar">
                  {tags
                    ?.filter((tag: any) =>
                      tag.slug.includes(`serum`)
                    )
                    .map((tag: any) => (
                      <SheetClose asChild key={tag.id}>
                        <Link
                          to={`/shop/hairType/${tag.id}/${tag.name}`}
                          className="text-[12px] font-light border-b pb-2 cursor-pointer border-b-neutral-200 dark:border-b-neutral-800 hover:text-neutral-600 "
                        >
                          {tag.name}
                        </Link>
                      </SheetClose>
                    ))}
                </AccordionContent>
              </AccordionItem>
              <AccordionItem
                value={"TREATMENTS"}
                className="dark:border-b dark:border-b-neutral-800 mt-5 pb-1 data-[state=open]:border-b-0"
              >
                <AccordionTrigger className="hover:no-underline hover:text-neutral-500 p-0 pb-2 font-normal">
                  <SheetClose
                    asChild
                    className={`flex justify-between hover:underline cursor-pointer items-center font-montserrat w-fit`}
                  >
                    <Link
                      to={`/shop/category/410/TREATMENTS`}
                    >
                      TREATMENTS
                    </Link>
                  </SheetClose>
                </AccordionTrigger>

                <AccordionContent className="flex flex-col gap-2 sm:max-h-[400px] p-2 max-h-[150px] min-h-[150px] overflow-scroll no-scrollbar">
                  {tags
                    ?.filter((tag: any) =>
                      tag.slug.includes(`treatment`)
                    )
                    .map((tag: any) => (
                      <SheetClose asChild key={tag.id}>
                        <Link
                          to={`/shop/hairType/${tag.id}/${tag.name}`}
                          className="text-[12px] font-light border-b pb-2 cursor-pointer border-b-neutral-200 dark:border-b-neutral-800 hover:text-neutral-600 "
                        >
                          {tag.name}
                        </Link>
                      </SheetClose>
                    ))}
                </AccordionContent>
              </AccordionItem>
              <AccordionItem
                value={"SERVICES"}
                className="dark:border-b dark:border-b-neutral-800 mt-5 pb-1 data-[state=open]:border-b-0"
              >
                <AccordionTrigger className="hover:no-underline hover:text-neutral-500 p-0 pb-2 font-normal">
                  <SheetClose
                    asChild
                    className={`flex justify-between hover:underline cursor-pointer items-center font-montserrat w-fit`}
                  >
                    <Link to={`/services`}>SERVICES</Link>
                  </SheetClose>
                </AccordionTrigger>

                <AccordionContent className="flex flex-col gap-2 sm:max-h-[400px] p-2 max-h-[150px] min-h-[150px] overflow-scroll no-scrollbar">
                  {amadeusServices.map((service: any) => (
                    <SheetClose asChild key={service.id}>
                      <Link
                        to={service.link}
                        className="text-[12px] font-light border-b cursor-pointer pb-2 border-b-neutral-200 dark:border-b-neutral-800 hover:text-neutral-600 "
                      >
                        {service.service}
                      </Link>
                    </SheetClose>
                  ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </TabsContent>
          <TabsContent
            value="collections"
            className="outline-none focus:outline-none"
          >
            <Accordion
              type="single"
              collapsible
              className="w-full text-sm"
            >
              {categories
                ?.filter((cat) => cat.parent === 367)
                .map((category) => (
                  <AccordionItem
                    value={category.name}
                    key={category.id}
                    className={`dark:border-b dark:border-b-neutral-800 mt-5 pb-3 data-[state=open]:border-b-0`}
                  >
                    <AccordionTrigger className="hover:no-underline hover:text-neutral-500 p-0 pb-2 font-normal">
                      <SheetClose
                        asChild
                        className={`flex justify-between hover:underline cursor-pointer items-center ont-montserrat w-fit`}
                      >
                        <Link
                          to={`/shop/category/${
                            category.id
                          }/${encodeURIComponent(
                            category.name
                          )}`}
                        >
                          {category.name.toLocaleUpperCase()}
                        </Link>
                      </SheetClose>
                    </AccordionTrigger>

                    <AccordionContent className="p-2 flex flex-col gap-2 sm:max-h-[400px] max-h-[250px] min-h-[150px] overflow-scroll no-scrollbar">
                      {categories
                        ?.filter(
                          (sub) =>
                            sub.parent === category.id
                        )
                        .map((sub) => {
                          return (
                            <SheetClose
                              asChild
                              key={sub.id}
                            >
                              <Link
                                to={`/shop/hairType/${sub.id}/${sub.name}`}
                                className="text-[12px] font-light border-b pb-2 cursor-pointer border-b-neutral-200 dark:border-b-neutral-800 hover:text-neutral-600"
                              >
                                {sub.name}
                              </Link>
                            </SheetClose>
                          );
                        })}
                    </AccordionContent>
                  </AccordionItem>
                ))}
            </Accordion>
          </TabsContent>
        </Tabs>

        <div className="flex justify-between items-end grow h-fit bg-white dark:bg-neutral-900">
          <ModeToggle scrolled={scrolled} drawer />

          <SocialIcons drawer />
        </div>
      </SheetContent>
    </Sheet>
  );
}
