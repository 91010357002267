import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import {
  course,
  category,
  subcategory,
} from "./interfaces";
import { apiCall } from "../../src/lib/api";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function searchCourseByTitle(
  courses: course[],
  searchQuery: string
) {
  return courses.filter((course) =>
    course.COURSE_TITLE.toLowerCase().includes(searchQuery)
  );
}
export function searchCategoryByTitle(
  courses: category[],
  searchQuery: string
) {
  const result = courses.filter((course) =>
    course.CategoryName.toLowerCase().includes(searchQuery)
  );
  return result;
}
export function searchSubcategoryByTitle(
  subcategories: subcategory[],
  searchQuery: string
) {
  return subcategories.filter((subcategory) =>
    subcategory.SubCategoryDesc.toLowerCase().includes(
      searchQuery
    )
  );
}

export function generateCartKey(
  length: number = 36
): string {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let key = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(
      Math.random() * chars.length
    );
    key += chars[randomIndex];
  }
  return key;
}

export async function addToCartRemoveFromWishlist(
  token: string,
  productId: string,
  wishlistId: number
) {
  if (token) {
    const storedArrayString =
      window.localStorage.getItem("wishlistArray");
    let res: { prod_id: number }[] = storedArrayString
      ? JSON.parse(storedArrayString)
      : [];

    // Find the index of the product in the array
    // Assuming you have the productId defined elsewhere
    const index = res.findIndex((item) => {
      return String(item.prod_id) === productId;
    });

    if (index > -1) {
      // Only splice array when item is found
      res.splice(index, 1);
      // Save the updated array back to localStorage
      window.localStorage.setItem(
        "wishlistArray",
        JSON.stringify(res)
      );
    }
    return;
  } else {
    const res = await fetch(
      `${apiCall}/wp-json/yith/wishlist/v1/wishlists/${wishlistId}/product/${productId}`,
      {
        method: "delete",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      throw new Error(`HTTP error: ${res.status}`);
    }
    if (res.status === 422) {
    }
    const response = await res.json();
    return response;
  }
}

export async function getResetLink(email: string) {
  try {
    const response = await fetch(
      `${apiCall}/wp-json/bdpwr/v1/reset-password`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      }
    );

    const data = await response.json();
    return data;
    // Set profile data to state
  } catch (error) {
    console.error("Error resetting password", error);
  }
}
const categoriesToFilter = [
  369, 472, 452, 414, 420, 390, 429, 424, 470, 456, 463,
  460, 468, 454, 462, 465, 445, 399, 421, 439, 380, 471,
  474, 453, 438, 435, 480, 408, 446, 410, 447,
];

export function productCategoryFilter(categories: any[]) {
  return categories.filter(
    (category) =>
      category.id &&
      categoriesToFilter.includes(category.id)
  );
}
