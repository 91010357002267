import {
  LogOut,
  HeartIcon,
  UserCircle,
  User,
} from "lucide-react";
import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
export function UserMenu({
  scrolled,
}: {
  scrolled: boolean;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = window.location.pathname;
  const [cookies, removeCookie, deleteCookie] = useCookies([
    "userToken",
    "cartKey",
    "userId",
  ]);
  const reactQuery = useQueryClient();
  const nav = useNavigate();
  const fname = window.localStorage.getItem("firstName");
  const lname = window.localStorage.getItem("lastName");

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="hidden">
        <User
          className={
            scrolled || location !== "/"
              ? "h-5 w-5 text-neutral-900 dark:text-neutral-200"
              : "h-5 w-5 text-neutral-200"
          }
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56 rounded-none">
        <DropdownMenuLabel className="cursor-pointer">
          {cookies.userToken ? fname + " " + lname : "Menu"}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <Link to="/profile">
            <DropdownMenuItem className="cursor-pointer">
              <UserCircle className="mr-2 h-4 w-4" />
              {cookies.userToken ? (
                <span>Profile</span>
              ) : (
                <span>Log in</span>
              )}
            </DropdownMenuItem>
          </Link>

          <Link to="/wishlist">
            <DropdownMenuItem className="cursor-pointer">
              <HeartIcon className="mr-2 h-4 w-4" />
              <span>Wishlist</span>
            </DropdownMenuItem>
          </Link>
        </DropdownMenuGroup>

        {cookies.userToken && <DropdownMenuSeparator />}
        {cookies.userToken && (
          <DropdownMenuItem
            className="cursor-pointer"
            onClick={() => {
              deleteCookie("userToken");
              deleteCookie("cartKey");
              deleteCookie("userId");
              localStorage.removeItem("wishlistArray");
              localStorage.removeItem("whislist_id");
              reactQuery.refetchQueries(["cartItems"]);
              reactQuery.refetchQueries(["homeProducts"]);
              reactQuery.refetchQueries(["wooProducts"]);
              reactQuery.refetchQueries([
                "LoggedInwishlist",
              ]);
              nav("/");
            }}
          >
            <LogOut className="mr-2 h-4 w-4" />
            <span>SIGN OUT</span>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
