import React from "react";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { useNavigate, useParams } from "react-router-dom";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { CheckIcon, LoaderIcon } from "lucide-react";
import { useToast } from "../../components/ui/use-toast";
import { apiCall } from "../../lib/api";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../src/components/ui/tooltip";
import { getResetLink } from "../../../src/lib/utils";

export default function ResetPassword() {
  const { toast } = useToast();
  const params = useParams();
  const email = params.email;
  const [passwordOne, setPasswordOne] = React.useState("");
  const [key, setKey] = React.useState("");
  const [passwordTwo, setPasswordTwo] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [unlockCode, setUnlockCode] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const nav = useNavigate();
  React.useEffect(() => {
    const listener = (event: any) => {
      if (
        event.code === "Enter" ||
        event.code === "NumpadEnter"
      ) {
        // tryLogin();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [passwordTwo]);

  const [timeLeft, setTimeLeft] =
    React.useState<number>(60);

  React.useEffect(() => {
    if (timeLeft <= 0) {
      setUnlockCode(true);
      return;
    } // Stop if timer reaches 0

    // Set up interval to decrease time every second
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clear interval on component unmount or when timeLeft changes
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  async function resetPass(e: any) {
    setLoader(true);
    e.preventDefault();

    const response = await fetch(
      `${apiCall}/wp-json/bdpwr/v1/set-password`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: key,
          email: email,
          password: passwordTwo,
        }),
      }
    );

    const result = await response.json();
    if (response.ok) {
      setLoader(false);
      console.log("succes result", result);
      setMessage(
        `Password reset successfully.${(
          <p
            onClick={() => nav("/login")}
            className="hover:underline"
          >
            SIGN IN
          </p>
        )}`
      );
    } else {
      setLoader(false);
      console.log("error result", result);
      setMessage(result.message || "Something went wrong.");
    }
  }
  return (
    <div className="w-screen h-dvh flex justify-center items-center flex-col p-10 ">
      <div className="shadow-2xl sm:w-96 w-full p-10 flex flex-col gap-1 items-center">
        <img
          src="/logo-aaa.png"
          alt="logo"
          width={50}
          className="dark:invert mb-5"
        />
        <h1 className="font-bold text-center text-xl mb-5">
          RESET PASSWORD
        </h1>
        <p className="text-center text-neutral-600 text-sm mb-5">
          Please enter the code you received by email and
          your new password
        </p>
        <form className="grid gap-2 py-4 w-full">
          <Input
            id="code"
            className="w-full outline-none rounded-none"
            placeholder="Code"
            autoComplete="code"
            type="text"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />

          <div className="flex gap-2 items-center justify-between relative">
            <Input
              id="passwordOne"
              className="col-span-3 outline-none rounded-none"
              type="password"
              autoComplete="new-password"
              placeholder="Enter New Password"
              value={passwordOne}
              onChange={(e) =>
                setPasswordOne(e.target.value)
              }
            />
            {passwordOne.length < 8 ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <ExclamationTriangleIcon
                      className="h-5 w-5 absolute right-2"
                      color="red"
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      Password should be 8 characters or
                      more
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              <CheckIcon
                color="green"
                className="h-5 w-5 absolute right-2"
              />
            )}
          </div>

          <div className="flex gap-2 items-center justify-between relative">
            <Input
              id="passwordTwo"
              className="col-span-3 outline-none rounded-none"
              placeholder="Repeat Password"
              type="password"
              autoComplete="repeat-password"
              value={passwordTwo}
              onChange={(e) =>
                setPasswordTwo(e.target.value)
              }
            />

            {passwordOne !== passwordTwo ||
            passwordOne === "" ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <ExclamationTriangleIcon
                      className="h-5 w-5 absolute right-2"
                      color="red"
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Passwords do not match</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              <CheckIcon
                color="green"
                className="h-5 w-5 absolute right-2"
              />
            )}
          </div>
        </form>
        <Button
          onClick={(e) => resetPass(e)}
          className="mt-1 border w-full rounded-none"
          disabled={
            passwordOne !== passwordTwo ||
            (passwordOne === "" && passwordTwo === "")
          }
        >
          {loader ? (
            <LoaderIcon className="animate-spin" />
          ) : (
            `RESET PASSWORD`
          )}
        </Button>
        <div className="flex gap-2 justify-end items-center w-full mt-2">
          <button
            onClick={() => {
              getResetLink(email || "");
              setUnlockCode(false);
              setTimeLeft(60);
              toast({ title: "New Code Requested" });
            }}
            disabled={!unlockCode}
            className="hover:underline text-sm cursor-pointer text-neutral-600 italic w-fit flex gap-2"
          >
            New Code
          </button>

          <span className="text-sm">{timeLeft}sec</span>
        </div>
      </div>

      <p className="pt-5">{message}</p>
    </div>
  );
}
