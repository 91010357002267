import { Label } from "../../../src/components/ui/label";
import { Button } from "../../../src/components/ui/button";
import { Input } from "../../../src/components/ui/input";
import { Textarea } from "../../../src/components/ui/textarea";
import React, { useState } from "react";
import { Clock, MapPin, Phone } from "lucide-react";
import { EnvelopeClosedIcon } from "@radix-ui/react-icons";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://amadeussabra.com/amadeus/wp-json/form-saver/v1/save-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        alert("Message sent successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
      } else {
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("An error occurred while sending the message.");
    }
  };

  return (
    <div className="flex flex-col w-screen font-montserrat lg:mt-36 mt-16 p-5 gap-5">
      <h1 className="text-2xl font-medium text-left mb-5">
        CONTACT US
      </h1>
      <div className="flex gap-5 sm:flex-row flex-col">
        <div className="w-full flex flex-col gap-10 border rounded-none p-5">
          <div className="flex gap-2 justify-start items-start">
            <Phone className="w-4 h-4 mt-1" />
            <div className="flex flex-col gap-2">
              <span className="text-md font-medium">
                Phone
              </span>
              <a
                className="text-sm font-light hover:underline"
                href="tel:+96108543113"
              >
                +961 8 543 113
              </a>
              <a
                className="text-sm font-light hover:underline"
                href="tel:+96108543115"
              >
                +961 8 543 115
              </a>
              <a
                className="text-sm font-light hover:underline"
                href="tel:+96103976780"
              >
                +961 3 976 780
              </a>
            </div>
          </div>
          <div className="flex gap-2 justify-start items-start">
            <MapPin className="w-4 h-4 mt-1" />
            <div className="flex flex-col gap-2">
              <span className="text-md font-medium">
                Location
              </span>
              <span className="text-sm font-light">
                Amadeus Sabra Center, Chtoura, Lebanon
              </span>
            </div>
          </div>
          <div className="flex gap-2 justify-start items-start">
            <EnvelopeClosedIcon className="w-4 h-4 mt-1" />
            <div className="flex flex-col gap-2">
              <span className="text-md font-medium">
                Email
              </span>
              <a
                className="text-sm font-light hover:underline"
                href="mailto:info@amadeussabra.com"
                target="_blank"
                rel="noreferrer"
              >
                Info@amadeussabra.com
              </a>
            </div>
          </div>
          <div className="flex gap-2 justify-start items-start">
            <Clock className="w-4 h-4 mt-1" />
            <div className="flex flex-col gap-2">
              <span className="text-md font-medium">
                Opening Hours
              </span>
              <span className="text-sm font-light">
                Tuesday - Friday: 08:00 - 19:00
              </span>
              <span className="text-sm font-light">
                Saturday - Sunday: 08:00 - 16:00
              </span>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.773810587045!2d35.84132947646602!3d33.818149730053676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f340a203d0c6d%3A0x44a75008ce22561f!2sAmadeus%20Sabra%20-%20The%20Beauty%20Factory!5e0!3m2!1sen!2slb!4v1730977989973!5m2!1sen!2slb"
            title="map"
            className="w-full h-full"
            loading="lazy"
            id="map"
          ></iframe>
        </div>
        <div className="flex flex-col justify-center w-full rounded-none sm:p-5 p-2">
          <h2 className="text-xl font-medium text-left mb-5">
            Submit Request
          </h2>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-4 w-full"
          >
            <div>
              <Label>
                First Name{" "}
                <span className="text-red-500">*</span>
              </Label>
              <Input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                className="w-full outline-none focus:outline-none focus:ring-0 rounded-none"
              />
            </div>
            <div>
              <Label>
                Last Name{" "}
                <span className="text-red-500">*</span>
              </Label>
              <Input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                className="w-full outline-none focus:outline-none focus:ring-0 rounded-none"
              />
            </div>
            <div>
              <Label>
                Email{" "}
                <span className="text-red-500">*</span>
              </Label>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full outline-none focus:outline-none focus:ring-0 rounded-none"
              />
            </div>
            <div>
              <Label>
                Phone{" "}
                <span className="text-red-500">*</span>
              </Label>
              <Input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="w-full outline-none focus:outline-none focus:ring-0 rounded-none"
              />
            </div>
            <div>
              <Label>
                Subject{" "}
                <span className="text-red-500">*</span>
              </Label>
              <Input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                className="w-full outline-none focus:outline-none focus:ring-0 rounded-none"
              />
            </div>
            <div>
              <Label>
                Message{" "}
                <span className="text-red-500">*</span>
              </Label>
              <Textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                maxLength={600}
                rows={4}
                className="w-full outline-none focus:outline-none focus:ring-0 rounded-none"
              />
            </div>
            <Button
              type="submit"
              className="rounded-none w-36"
            >
              SUBMIT
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
