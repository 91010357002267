import React, { useRef, useEffect, useState } from "react";
import { milestones } from "./content";
import MissionVision from "./missionVision";

export function Milestones() {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [moveToMileStones, setMoveToMilestones] =
    useState(false);
  const lastMileStone = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (lastMileStone.current) {
        if (scrollY > window.innerHeight + 2000) {
          setMoveToMilestones(true);
        } else {
          setMoveToMilestones(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () =>
      window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      ref={scrollRef}
      className="overflow-y-auto h-screen no-scrollbar scroll-smooth"
    >
      <div
        className={`flex flex-col h-screen items-center `}
      >
        {/* Line passing through milestones */}
        <div className=" lg:block hidden absolute h-screen left-1/2 right-0 w-0.5 bg-neutral-700 dark:bg-neutral-700"></div>
        {milestones.map((milestone, index) => {
          const isLast = index === 3;

          return (
            <div
              key={milestone.id}
              className={`w-screen flex items-center justify-center h-screen `}
              ref={isLast ? lastMileStone : null}
            >
              <div
                className={`flex h-screen sm:flex-row flex-col justify-center items-center sm:w-1/2 w-full sm:gap-10 gap-5`}
              >
                <img
                  src={`/salon-${milestone.id}.jpg`}
                  alt=""
                  className="border sm:w-96 w-64"
                />
                <div className="w-full bg-neutral-900 flex flex-col justify-center items-center sm:items-start p-5">
                  <h3 className="sm:text-xl text-md text-neutral-200 font-trajan">
                    {milestone.title.toUpperCase()}
                  </h3>
                  <span className="text-neutral-200 font-trajan sm:text-xl text-md">
                    {milestone.year}
                  </span>
                  <p className="text-sm text-neutral-400 sm:text-left text-center">
                    {milestone.description}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div
        className={`duration-700 flex items-center justify-center fixed top-0 w-screen bg-neutral-900 overflow-x-scroll ${
          !moveToMileStones ? "translate-y-[100vh]" : ""
        }`}
      >
        <MissionVision />
      </div>
    </div>
  );
}
